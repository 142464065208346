@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,600,700,800');
html,body{
    padding: 0;
    margin: 0;
}
.homelogin {    
    font-size: 14px;
    color: #5b5b5b;
}

.block {
    display: block;
}
.color-primary,
.text-primary {
    color: #000 !important ;
}
a.active,
button.active,
a:focus,
button:focus,
a:hover,
button:hover {
    color: #f5519e;
    outline: none !important;
    text-decoration: none !important;
}
.homelogin {
    font-size: 14px;
    color:  #5b5b5b;
    background: url('../images/bg.jpg') center top no-repeat;
    background-size: cover;
}
.homelogin::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    background: -webkit-linear-gradient(bottom, #fff, #00c6fb);
    background: -o-linear-gradient(bottom, #fff, #00c6fb);
    background: -moz-linear-gradient(bottom, #fff, #00c6fb);
    background: linear-gradient(bottom, #fff, #00c6fb);
    opacity: 0.7;
}
.mggAlert {
    width: 450px;
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 20;
    background: #fff;
    min-height: 150px;
    border-radius: 10px;
    box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);
    color: #000;
    padding: 15px;
    height: auto;
    display: none;
    border:1px solid #000
}
.separador {
    clear: both;
    display: block;
    width: 100%;
}
.mggAlert label {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 22px;
}

.mggAlert button {
    display: block;
    margin: 30px auto 0 auto;
    background: #000;
    color: white;
    cursor: pointer;
    padding: 8px 13px 8px 13px;
    border: 0;
    font-size: 18px;
    border-radius: 10px;
}

.mggAlert button:hover {
    background: #000;
}
.makeStyles-wrapper-5 {
    flex: none;
    width: 100%;
    margin: 10% auto 0 auto;
    max-width: 400px;
    background-color: #251972;
    padding: 30px;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    color: white;
    -webkit-box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 6px 14px 0px rgba(0, 0, 0, 0.75);

}

.makeStyles-wrapper-5 .MuiInputBase-input {
    font-weight: bold;
}
.text-xs-center {
    text-align: center;
}
.conselectorcedi,btncedis {
    display: none
}
.pb-xs {
    padding-bottom: 10px;
}

.btngeneral {
    background-color: #f5519e !Important;
    color: #fff !Important;
    width: 100%;
    border: 0;
    cursor: pointer;
}

.MuiButton-root {
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: initial;
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

.pt-1 {
    padding-top: 1rem !important;
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: #e8f0fe;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    margin: 0px 0 20px 0;
    color: black;
}

.btnrecover {
    border: 0;
    background: transparent;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
.logologin {
    margin: 0 auto;
    max-width: 150px;
}
.homeicons svg {
    font-size: 40px;
}
.color-success,
.text-success {
    color: #26dad2;
}
.color-warning,
.text-warning {
    color: #ffb64d;
}
.color-danger,
.text-danger {
    color: #fc6180;
}
.card {
    background: #ffffff none repeat scroll 0 0;
    margin: 15px 0;
    padding: 20px;
    border: 0 solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.card {
    margin-bottom: 30px;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.media-text-right {
    text-align: right;
}
.media-body {
    vertical-align: middle;
}
